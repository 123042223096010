import request from '@/utils/request'

const customs_orderApi = {
  customs_order_list: '/e/customs_order/',
  customs_order_push: '/e/customs_order/',
  customs_order_delete: '/e/customs_order/',
  customs_order_goods_list: '/e/customs_order/',
  customs_payment_check_list: 'e/customs_payment_check/',
  customs_logistics_list: '/e/customs_logistics/',
  customs_logistics_push: '/e/customs_logistics/',
  customs_logistics_delete: '/e/customs_logistics/',
  customs_inventory_list: '/e/customs_inventory/',
  customs_inventory_push: '/e/customs_inventory/',
  customs_inventory_delete: '/e/customs_inventory/',
  customs_inventory_order_list: '/e/customs_inventory/',
  corporation_config_list: '/e/corporation_config/',
  corporation_config_create: '/e/corporation_config/',
  corporation_config_update: '/e/corporation_config/',
  corporation_config_delete: '/e/corporation_config/'
}

/**
 * 列表
 */
export function customs_order_list (parameter) {
  return request({
    url: customs_orderApi.customs_order_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 推送订单
 */
export function customs_order_push (parameter, order_id) {
  return request({
    url: customs_orderApi.customs_order_push + order_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function customs_order_delete (order_id) {
  return request({
    url: customs_orderApi.customs_order_delete + order_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 单个订单的商品列表
 */
export function customs_order_goods_list (order_id) {
  return request({
    url: customs_orderApi.customs_order_goods_list + order_id + '/goods/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 支付单检查列表
 */
export function customs_payment_check_list (parameter) {
  return request({
    url: customs_orderApi.customs_payment_check_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 运单列表
 */
export function customs_logistics_list (parameter) {
  return request({
    url: customs_orderApi.customs_logistics_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 推送订单
 */
export function customs_logistics_push (parameter, order_id) {
  return request({
    url: customs_orderApi.customs_logistics_push + order_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function customs_logistics_delete (order_id) {
  return request({
    url: customs_orderApi.customs_logistics_delete + order_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function customs_inventory_list (parameter) {
  return request({
    url: customs_orderApi.customs_inventory_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 推送订单
 */
export function customs_inventory_push (parameter, order_id) {
  return request({
    url: customs_orderApi.customs_inventory_push + order_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function customs_inventory_delete (order_id) {
  return request({
    url: customs_orderApi.customs_inventory_delete + order_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 单个订单的列表
 */
export function customs_inventory_order_list (order_id) {
  return request({
    url: customs_orderApi.customs_inventory_order_list + order_id + '/list/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 配置列表
 */
export function corporation_config_list (parameter) {
  return request({
    url: customs_orderApi.corporation_config_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 创建配置
 */
export function corporation_config_create (parameter) {
  return request({
    url: customs_orderApi.corporation_config_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function corporation_config_update (parameter, config_id) {
  return request({
    url: customs_orderApi.corporation_config_update + config_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function corporation_config_delete (config_id) {
  return request({
    url: customs_orderApi.corporation_config_delete + config_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
