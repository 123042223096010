<template>
  <a-modal
    title="订单详情"
    :width="1080"
    :visible="visible"
    @cancel="() => { $emit('cancel') }"
  >
    <a-form v-bind="formLayout">
      <a-row :gutter="[4,4]">
        <a-col :span="24">
          <a-form-item label="订单号">
            <p>{{ model.order_no }}</p>
          </a-form-item>
          <a-form-item label="订单编号">
            <p>{{ model.guid }}</p>
          </a-form-item>
          <a-form-item label="电商平台名称">
            <p>{{ model.ebp_name }}</p>
          </a-form-item>
          <a-form-item label="电商平台代码">
            <p>{{ model.ebp_code }}</p>
          </a-form-item>
          <a-form-item label="电商企业名称">
            <p>{{ model.ebc_name }}</p>
          </a-form-item>
          <a-form-item label="电商企业代码">
            <p>{{ model.ebc_code }}</p>
          </a-form-item>
          <a-form-item label="版本">
            <p>{{ model.version }}</p>
          </a-form-item>
          <a-form-item label="报文编号">
            <p>{{ model.message_guid }}</p>
          </a-form-item>
          <a-form-item label="创建时间">
            <p>{{ model.create_time }}</p>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>

// const fields = ['id', 'number', 'corporation_id']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f'
      // form: this.$form.createForm(this)
    }
  },
  created () {
    console.log(this.visible)
    // 防止表单未注册
    // fields.forEach(v => this.form.getFieldDecorator(v))
  },
  methods: {
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
